import React from 'react'
import './style.css'

export const Logo = React.forwardRef(({ c1, c2, ...rest }, ref) => {
    const color = { c1: c1 ? c1 : '#009966', c2: c2 ? c2 : '#FFCC33' }
    return (
        <svg ref={ref} {...rest} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="500px"
            height="500px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
            <g>
                <g>
                    <g className='logo-svg-c2'>
                        <path fill={color.c2} d="M96.916,373.604c-34.871,0-63.203,28.33-63.203,63.199c0.002,34.869,28.334,63.201,63.201,63.199
				c34.871,0,63.199-28.332,63.199-63.199C160.113,401.934,131.785,373.604,96.916,373.604z M96.914,450.969
				c-7.627,0-14.168-6.537-14.166-14.166c0-8.717,6.539-15.256,14.168-15.256c7.627,0,14.164,6.539,14.164,15.256
				C111.08,444.432,104.543,450.969,96.914,450.969z"/>
                        <path fill={color.c2} d="M401.432,0c-34.869,0-63.201,28.33-63.201,63.199c0.002,34.869,28.334,63.201,63.199,63.197
				c34.871,0,63.203-28.33,63.203-63.197C464.633,28.33,436.299,0,401.432,0z M401.43,77.362c-7.627,0-14.164-6.539-14.164-14.163
				c0-8.719,6.539-15.256,14.166-15.256s14.164,6.537,14.164,15.256C415.596,70.825,409.059,77.362,401.43,77.362z"/>
                    </g>
                    <g className='logo-svg-c1'>
                        <path fill={color.c1} d="M159.461,392.006l80.893-29.441l56.006-120.109l152.564-55.53l-19.754-54.269
				c-31.742,11.554-66.838,1.137-86.49-24.178c-0.375-1.023-1.771-1.676-2.143-2.699l-80.889,29.439l-55.355,118.717l-153.591,55.9
				l19.751,54.268c31.744-11.553,67.214-0.111,86.865,25.203C157.691,390.33,159.088,390.984,159.461,392.006z"/>
                        <path fill={color.c1} d="M159.488,481.283l141.303-51.428l56.002-120.109l80.24-28.043l0,0l55.867,26.049l-14.908-40.957
				l-14.906-40.959l-40.961,14.908l0,0l0,0l-96.246,35.033l-56.008,120.107l-96.248,35.031c0.371,1.025-0.277,2.418,0.096,3.443
				C173.814,450.557,169.443,467.223,159.488,481.283z"/>
                        <path fill={color.c1} d="M7.1,190.034l55.863,26.05l0,0l80.238-28.046l56.011-120.109l141.3-51.427
				c-9.957,14.061-14.699,29.701-14.23,46.922c0.373,1.023-0.279,2.42,0.094,3.444L230.129,101.9l-56.01,120.109l-96.25,35.035
				l-40.955,14.906l-14.908-40.959L7.1,190.034z"/>
                    </g>
                    <g>
                        <path opacity="0.1" fill="#FFFFFF" d="M60.798,306.16c21.437,9.27,45.071,14.414,69.913,14.414
				c97.393,0,176.348-78.951,176.346-176.347c0.002-8.692-0.639-17.233-1.854-25.589l-45.555,16.58l-55.355,118.717L60.798,306.16z"
                        />
                        <path opacity="0.1" fill="#FFFFFF" d="M273.588,40.859l-74.376,27.069L143.201,188.04l-80.239,28.044L7.1,190.034
				l14.905,40.958l14.907,40.959l40.956-14.906l96.251-35.035l56.01-120.109l64.266-23.392
				C288.992,65.059,281.975,52.43,273.588,40.859z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
})

export const Logo2 = React.forwardRef(({ ...rest }, ref) => {

    return (
        <svg ref={ref} {...rest} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1425px"
            height="500px" viewBox="0 0 1425 500" enableBackground="new 0 0 1425 500">
            <g>
                <g>
                    <g>
                        <g>
                            <rect x="1263.055" y="361.807" fill="#FFCC29" width="102" height="14.998" />
                            <rect x="1263.055" y="294.803" fill="#FFCC29" width="15" height="68.004" />
                            <rect x="553.053" y="278.803" fill="#FFCC29" width="725.002" height="16" />
                            <path fill="#FFCC29" d="M1382.934,340.014c-16.32,5.939-24.939,23.516-18.998,39.834c5.939,16.322,24.025,24.756,40.346,18.814
					c16.32-5.939,24.754-24.025,18.814-40.346C1417.154,341.996,1399.254,334.072,1382.934,340.014z M1396.113,376.223
					c-3.568,1.299-7.744-0.648-9.229-4.727c-1.301-3.57,0.645-7.744,4.217-9.043c3.568-1.299,7.744,0.648,9.043,4.215
					C1401.629,370.75,1399.684,374.922,1396.113,376.223z"/>
                        </g>
                        <g>
                            <path fill="#800000018000000180000001" d="M625.352,330.222h-37.717v14.404h33.582c4.74,0,7.983,0.847,9.731,2.539
					c1.747,1.693,2.622,4.899,2.622,9.619v5.811c0,4.753-0.857,7.976-2.571,9.668c-1.714,1.693-4.976,2.539-9.782,2.539h-31.111
					c-4.84,0-8.135-0.854-9.883-2.563s-2.621-4.923-2.621-9.644v-1.416l8.27-1.855v8.301h39.682v-15.527h-33.48
					c-4.84,0-8.118-0.854-9.832-2.563c-1.715-1.709-2.572-4.923-2.572-9.644v-4.492c0-4.72,0.857-7.935,2.572-9.644
					c1.714-1.709,4.992-2.563,9.832-2.563h28.389c4.739,0,8.008,0.839,9.807,2.515c1.798,1.677,2.697,4.614,2.697,8.813v1.074
					l-7.613,2.1V330.222z"/>
                            <path fill="#800000018000000180000001" d="M655.756,342.478c0-3.873,0.757-6.519,2.27-7.935s4.336-2.124,8.471-2.124h31.615
					c4.135,0,6.959,0.708,8.471,2.124c1.514,1.416,2.27,4.062,2.27,7.935v22.314c0,3.874-0.748,6.511-2.244,7.91
					c-1.496,1.4-4.328,2.1-8.496,2.1h-31.615c-4.202,0-7.043-0.691-8.521-2.075c-1.479-1.383-2.219-4.028-2.219-7.935V342.478z
					 M664.025,368.405h36.557v-29.688h-36.557V368.405z"/>
                            <path fill="#800000018000000180000001" d="M728.063,374.802v-42.383h8.27v35.986h34.035v6.396H728.063z" />
                            <path fill="#800000018000000180000001" d="M791.596,332.419v35.986h31.967v-35.986h8.371v32.373
					c0,3.906-0.748,6.552-2.244,7.935c-1.496,1.384-4.328,2.075-8.496,2.075h-27.127c-4.17,0-7.002-0.699-8.496-2.1
					c-1.496-1.399-2.244-4.036-2.244-7.91v-32.373H791.596z"/>
                            <path fill="#800000018000000180000001" d="M889.971,338.718h-30.557v29.688h30.557v-10.107l8.27,1.514v4.98
					c0,3.874-0.748,6.511-2.244,7.91c-1.496,1.4-4.328,2.1-8.496,2.1h-25.715c-4.17,0-6.984-0.675-8.447-2.026
					c-1.461-1.351-2.193-4.012-2.193-7.983v-22.314c0-3.938,0.74-6.6,2.219-7.983c1.479-1.383,4.287-2.075,8.422-2.075H887.5
					c4.168,0,7,0.708,8.496,2.124s2.244,4.062,2.244,7.935v4.004l-8.27,1.318V338.718z"/>
                            <path fill="#800000018000000180000001" d="M923.855,374.802h-8.219v-42.285h8.219V374.802z" />
                            <path fill="#800000018000000180000001" d="M942.863,342.478c0-3.873,0.756-6.519,2.27-7.935
					c1.512-1.416,4.336-2.124,8.471-2.124h31.615c4.135,0,6.959,0.708,8.471,2.124c1.514,1.416,2.27,4.062,2.27,7.935v22.314
					c0,3.874-0.748,6.511-2.244,7.91c-1.496,1.4-4.328,2.1-8.496,2.1h-31.615c-4.203,0-7.043-0.691-8.521-2.075
					c-1.479-1.383-2.219-4.028-2.219-7.935V342.478z M951.133,368.405h36.557v-29.688h-36.557V368.405z"/>
                            <path fill="#800000018000000180000001" d="M1022.18,340.915c0.201,1.237,0.354,2.49,0.453,3.76
					c0.102,1.27,0.152,2.539,0.152,3.809v26.318h-7.615v-42.383h6.303l32.121,27.686c0.939,0.781,1.84,1.645,2.697,2.588
					c0.857,0.944,1.705,2.019,2.545,3.223c-0.234-1.27-0.412-2.555-0.529-3.857c-0.117-1.302-0.176-2.652-0.176-4.053v-25.586h7.613
					v42.383h-5.646l-32.775-28.076c-1.143-1.041-2.127-2.026-2.949-2.954C1023.549,342.844,1022.818,341.892,1022.18,340.915z"/>
                            <path fill="#800000018000000180000001" d="M1086.014,374.802v-42.383h44.07v6.299h-35.801v10.889h21.582v6.299h-21.582v12.695
					h36.205v6.201H1086.014z"/>
                            <path fill="#800000018000000180000001" d="M1184.641,338.718h-30.707v10.986h28.086c4.1,0,6.898,0.692,8.395,2.075
					c1.496,1.384,2.244,4.028,2.244,7.935v5.078c0,3.906-0.73,6.552-2.193,7.935c-1.463,1.384-4.277,2.075-8.445,2.075h-26.623
					c-4.17,0-7.002-0.691-8.496-2.075c-1.496-1.383-2.244-4.028-2.244-7.935v-1.172l7.563-1.514v6.299h33.027v-11.914h-28.186
					c-4.135,0-6.943-0.691-8.422-2.075c-1.479-1.383-2.219-4.028-2.219-7.935v-4.004c0-3.873,0.74-6.519,2.219-7.935
					s4.287-2.124,8.422-2.124h24.354c4.066,0,6.875,0.684,8.42,2.051c1.547,1.367,2.32,3.776,2.32,7.227v1.025l-7.514,1.563V338.718
					z"/>
                            <path fill="#800000018000000180000001" d="M657.118,464.802V413.19h51.331v6.934h-42.406v14.355h25.918v6.836h-25.918v16.406
					h42.809v7.08H657.118z"/>
                            <path fill="#800000018000000180000001" d="M726.348,464.802v-42.383h8.27v35.986h34.035v6.396H726.348z" />
                            <path fill="#800000018000000180000001" d="M781.611,464.802v-42.383h44.07v6.299h-35.801v10.889h21.582v6.299h-21.582v12.695
					h36.205v6.201H781.611z"/>
                            <path fill="#800000018000000180000001" d="M879.381,428.718h-30.555v29.688h30.555v-10.107l8.27,1.514v4.98
					c0,3.874-0.748,6.511-2.244,7.91c-1.496,1.4-4.328,2.1-8.496,2.1h-25.715c-4.168,0-6.984-0.675-8.445-2.026
					c-1.463-1.351-2.193-4.012-2.193-7.983v-22.314c0-3.938,0.738-6.6,2.219-7.983c1.479-1.383,4.285-2.075,8.42-2.075h25.715
					c4.168,0,7,0.708,8.496,2.124s2.244,4.062,2.244,7.935v4.004l-8.27,1.318V428.718z"/>
                            <path fill="#800000018000000180000001" d="M926.275,428.718v36.084h-8.219v-36.084h-20.674v-6.299h49.666v6.299H926.275z" />
                            <path fill="#800000018000000180000001" d="M959.453,464.802v-42.383h35.195c3.26,0,5.604,0.644,7.033,1.929
					c1.43,1.286,2.143,3.378,2.143,6.274v9.473c0,2.865-0.713,4.932-2.143,6.201s-3.773,1.904-7.033,1.904h-5.799l19.313,16.602
					h-11.043L979.672,448.2h-11.949v16.602H959.453z M991.018,428.62h-23.295v13.672h23.295c1.848,0,3.092-0.293,3.73-0.879
					s0.959-1.725,0.959-3.418v-5.078c0-1.563-0.336-2.669-1.008-3.32C994.025,428.946,992.799,428.62,991.018,428.62z"/>
                            <path fill="#800000018000000180000001" d="M1021.676,432.478c0-3.873,0.756-6.519,2.268-7.935
					c1.514-1.416,4.338-2.124,8.473-2.124h31.615c4.135,0,6.957,0.708,8.471,2.124c1.512,1.416,2.268,4.062,2.268,7.935v22.314
					c0,3.874-0.748,6.511-2.242,7.91c-1.496,1.4-4.33,2.1-8.496,2.1h-31.615c-4.203,0-7.043-0.691-8.521-2.075
					c-1.48-1.383-2.219-4.028-2.219-7.935V432.478z M1029.945,458.405h36.557v-29.688h-36.557V458.405z M1041.34,416.999
					l10.539-16.992h8.975l-14.168,16.992H1041.34z"/>
                            <path fill="#800000018000000180000001" d="M1100.99,430.915c0.201,1.237,0.354,2.49,0.453,3.76
					c0.102,1.27,0.152,2.539,0.152,3.809v26.318h-7.615v-42.383h6.303l32.121,27.686c0.939,0.781,1.84,1.645,2.697,2.588
					c0.857,0.944,1.705,2.019,2.545,3.223c-0.234-1.27-0.412-2.555-0.529-3.857c-0.117-1.302-0.176-2.652-0.176-4.053v-25.586h7.613
					v42.383h-5.646l-32.775-28.076c-1.143-1.041-2.127-2.026-2.949-2.954C1102.359,432.844,1101.629,431.892,1100.99,430.915z"/>
                            <path fill="#800000018000000180000001" d="M1172.844,464.802h-8.219v-42.285h8.219V464.802z" />
                            <path fill="#800000018000000180000001" d="M1230.678,428.718h-30.557v29.688h30.557v-10.107l8.27,1.514v4.98
					c0,3.874-0.748,6.511-2.244,7.91c-1.496,1.4-4.328,2.1-8.496,2.1h-25.715c-4.17,0-6.984-0.675-8.447-2.026
					c-1.461-1.351-2.193-4.012-2.193-7.983v-22.314c0-3.938,0.74-6.6,2.219-7.983c1.479-1.383,4.287-2.075,8.422-2.075h25.715
					c4.168,0,7,0.708,8.496,2.124s2.244,4.062,2.244,7.935v4.004l-8.27,1.318V428.718z"/>
                            <path fill="#800000018000000180000001" d="M1249.385,464.802l25.313-42.383h7.563l25.918,42.383h-8.975l-5.699-9.912h-30.254
					l-5.596,9.912H1249.385z M1266.73,448.786h23.244l-11.646-20.166L1266.73,448.786z"/>
                            <path fill="#800000018000000180000001" d="M1359.912,428.718h-30.707v10.986h28.084c4.102,0,6.9,0.692,8.396,2.075
					c1.494,1.384,2.244,4.028,2.244,7.935v5.078c0,3.906-0.732,6.552-2.193,7.935c-1.463,1.384-4.279,2.075-8.447,2.075h-26.623
					c-4.168,0-7-0.691-8.496-2.075c-1.496-1.383-2.244-4.028-2.244-7.935v-1.172l7.564-1.514v6.299h33.027v-11.914h-28.188
					c-4.135,0-6.941-0.691-8.42-2.075c-1.48-1.383-2.219-4.028-2.219-7.935v-4.004c0-3.873,0.738-6.519,2.219-7.935
					c1.479-1.416,4.285-2.124,8.42-2.124h24.355c4.066,0,6.873,0.684,8.42,2.051c1.545,1.367,2.318,3.776,2.318,7.227v1.025
					l-7.512,1.563V428.718z"/>
                        </g>
                        <g>
                            <path d="M739.512,76.471l65.123,136.713c2.178,4.682,6.369,7.88,12.572,9.597c4.023,1.145,6.035,3.72,6.035,7.726
					c0,0.479-0.252,3.34-0.756,8.584c-0.502,2.288-4.025,3.433-10.563,3.433H711.832c-3.689,0-6.121-0.379-7.291-1.145
					c-1.176-0.762-1.76-2.86-1.76-6.294c0-0.572,0.164-3.052,0.502-7.439c0.504-1.717,2.516-2.955,6.035-3.72
					c5.361-1.049,8.045-2.814,8.045-5.296c0-0.668-0.168-1.479-0.502-2.434l-35.221-72.197l-8.531,10.774v58.413
					c0,4.869,3.773,8.594,11.316,11.168c3.184,1.051,4.777,3.434,4.777,7.154c0,4.389-0.672,7.32-2.012,8.798
					c-1.344,1.48-4.611,2.217-9.807,2.217H572.037c-3.859,0-6.539-0.455-8.047-1.367s-2.262-3.481-2.262-7.71
					c0-4.225,0.584-6.892,1.76-7.996c1.17-1.104,3.434-2.09,6.787-2.955c6.199-1.428,9.305-4.002,9.305-7.719V43.082
					c0-4.287-3.018-7.482-9.053-9.578c-4.023-1.432-6.455-2.598-7.291-3.506c-0.842-0.905-1.258-2.789-1.258-5.65
					c0-6.485,0.416-10.229,1.258-11.231c0.836-1.001,3.268-1.502,7.291-1.502H685.18c5.363,0,8.047,1.241,8.047,3.72
					c0,7.726-0.252,12.186-0.754,13.377c-0.504,1.193-2.936,2.504-7.293,3.934c-8.045,2.383-12.07,5.43-12.07,9.146v73.304
					l54.633-70.902c3.018-3.854,4.527-6.771,4.527-8.748c0-1.221-2.016-2.678-6.047-4.372c-4.385-1.717-7.043-3.194-7.971-4.435
					c-0.928-1.238-1.391-4.387-1.391-9.443c0-3.719,2.436-5.579,7.314-5.579h66.836c3.865,0,6.219,0.427,7.059,1.278
					c0.842,0.854,1.262,3.315,1.262,7.387c0,4.356-0.547,7.008-1.635,7.955c-1.092,0.948-4.486,2.084-10.182,3.409
					c-4.361,0.954-7.127,1.72-8.299,2.292c-1.174,0.572-2.6,1.813-4.273,3.721L739.512,76.471z"/>
                            <path d="M1021.393,11.614h89.008c6.033,0,9.971,0.501,11.816,1.502c1.842,1.002,2.766,3.555,2.766,7.654
					c0,3.72-0.629,6.319-1.885,7.797c-1.258,1.479-4.318,2.886-9.178,4.221c-4.361,1.145-7.127,2.386-8.297,3.72
					c-1.176,1.337-1.76,3.529-1.76,6.581v169.819c0,2.482,0.502,4.293,1.508,5.438s3.771,2.338,8.297,3.576
					c6.199,1.717,9.303,4.055,9.303,7.01c0,7.439-0.42,11.565-1.256,12.376c-0.842,0.811-3.771,1.215-8.801,1.215h-112.139
					c-4.863,0-7.291-2.336-7.291-7.009c0-4.006,0.252-6.986,0.754-8.942c0.504-1.953,3.604-3.552,9.303-4.792
					c6.369-1.432,9.555-3.957,9.555-7.583V93.734l-45.258,97.285h-27.656l-44.504-94.281v117.029c0,3.815,3.018,6.63,9.051,8.44
					c7.375,2.099,11.064,5.818,11.064,11.159c0,6.104-3.689,9.155-11.064,9.155H850.17c-6.205,0-9.305-2.908-9.305-8.726
					c0-4.101,2.598-7.248,7.795-9.443c3.854-1.811,6.285-3.266,7.291-4.363c1.006-1.096,1.51-3.026,1.51-5.794V48.955
					c0-4.578-0.715-8.084-2.137-10.516c-1.428-2.433-4.488-4.41-9.178-5.938c-5.029-1.524-7.543-3.098-7.543-4.721l0.252-6.152
					c0.164-4.482,0.711-7.271,1.633-8.369c0.92-1.096,3.143-1.646,6.664-1.646h93.029l40.229,88.415L1021.393,11.614z"/>
                            <path d="M1254.266,163.837v47.642c0,5.532,4.189,9.156,12.572,10.873c4.023,0.857,6.828,2.027,8.424,3.505
					c1.59,1.479,2.389,4.22,2.389,8.226c0,3.816-0.924,6.177-2.768,7.081c-1.846,0.908-5.531,1.359-11.063,1.359h-113.898
					c-6.033,0-9.051-3.004-9.051-9.013c0-4.768,0.416-7.7,1.258-8.798c0.836-1.096,3.684-2.075,8.549-2.933
					c4.355-0.763,7.078-1.597,8.17-2.504c1.09-0.906,1.635-2.455,1.635-4.65V41.801c0-4.1-4.443-7.39-13.326-9.871
					c-4.693-1.431-7.125-3.623-7.291-6.581l-0.252-6.724c0-2.861,0.629-4.744,1.887-5.651c1.258-0.905,4.063-1.359,8.422-1.359
					h128.732c37.043,0,66.586,6.749,88.631,20.244c22.039,13.497,33.063,32.167,33.063,56.011c0,13.447-3.395,25.418-10.184,35.909
					c-6.789,10.493-15.336,18.219-25.645,23.177c-10.309,4.961-22.213,9.014-35.703,12.16c-13.496,3.148-27.871,4.722-43.121,4.722
					H1254.266z M1254.266,27.494v119.891h17.602c6.033,0,11.439-1.883,16.217-5.651c4.777-3.767,7.166-10.037,7.166-18.813V51.529
					c0-16.023-9.391-24.035-28.16-24.035H1254.266z"/>
                        </g>
                        <g>
                            <path opacity="0.2" fill="#FFFFFF" d="M791.137,11.989h-15.346c2.471,7.112,5.297,14.056,8.459,20.813
					c1-0.246,2.119-0.506,3.391-0.784c5.695-1.325,9.09-2.461,10.182-3.409c1.088-0.947,1.635-3.599,1.635-7.955
					c0-4.071-0.42-6.532-1.262-7.387C797.355,12.416,795.002,11.989,791.137,11.989z"/>
                            <path opacity="0.2" fill="#FFFFFF" d="M1122.342,13.491c-1.846-1.001-5.783-1.502-11.816-1.502h-89.008l-40.982,88.415
					l-40.229-88.415h-93.029c-3.521,0-5.744,0.55-6.664,1.646c-0.922,1.098-1.469,3.887-1.633,8.369l-0.252,6.152
					c0,1.623,2.514,3.196,7.543,4.721c4.689,1.527,7.75,3.505,9.178,5.938c1.422,2.432,2.137,5.938,2.137,10.516v70.521
					c11.926,8.317,24.719,15.478,38.217,21.319V97.113l25.076,53.127c19.607,5.758,40.348,8.861,61.816,8.861
					c0.098,0,0.195-0.003,0.293-0.003l30.232-64.989v62.865c33.225-4.635,64.061-16.718,90.768-34.518V43.464
					c0-3.052,0.584-5.244,1.76-6.581c1.17-1.334,3.936-2.575,8.297-3.72c4.859-1.335,7.92-2.741,9.178-4.221
					c1.256-1.478,1.885-4.077,1.885-7.797C1125.107,17.046,1124.184,14.493,1122.342,13.491z"/>
                            <path opacity="0.2" fill="#FFFFFF" d="M1189.477,11.614h-39.555c-4.359,0-7.164,0.454-8.422,1.359
					c-1.258,0.907-1.887,2.79-1.887,5.651l0.252,6.724c0.166,2.958,2.598,5.15,7.291,6.581c8.883,2.481,13.326,5.771,13.326,9.871
					v25.633C1172.672,50.479,1182.494,31.713,1189.477,11.614z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill="#FFCC29" d="M89.814,373.604c-34.869,0-63.2,28.328-63.2,63.197c0.001,34.869,28.332,63.201,63.2,63.199
				c34.869,0,63.199-28.332,63.199-63.199C153.013,401.933,124.683,373.604,89.814,373.604z M89.813,450.968
				c-7.627,0-14.166-6.539-14.165-14.166c0-8.717,6.539-15.254,14.166-15.254c7.627,0,14.165,6.537,14.164,15.254
				C103.98,444.431,97.442,450.968,89.813,450.968z"/>
                        <path fill="#FFCC29" d="M394.328-0.001c-34.869-0.001-63.199,28.33-63.199,63.199s28.332,63.2,63.199,63.198
				c34.869,0,63.199-28.331,63.199-63.198C457.527,28.329,429.197-0.002,394.328-0.001z M394.328,77.363
				c-7.629,0-14.166-6.539-14.166-14.165c0.002-8.718,6.539-15.255,14.166-15.255s14.166,6.537,14.164,15.255
				C408.494,70.826,401.955,77.363,394.328,77.363z"/>
                    </g>
                    <g>
                        <path fill="#00A859" d="M152.36,392.005l80.891-29.439l56.005-120.112l152.563-55.529l-19.752-54.268
				c-31.74,11.553-66.84,1.137-86.49-24.179c-0.373-1.024-1.77-1.676-2.143-2.7l-80.887,29.441l-55.357,118.714l-153.587,55.9
				l19.751,54.27c31.743-11.551,67.213-0.113,86.864,25.203C150.591,390.331,151.987,390.983,152.36,392.005z"/>
                        <path fill="#00A859" d="M152.387,481.282l141.301-51.428l56.004-120.111l80.24-28.043l0,0l55.863,26.049l-14.906-40.957
				l-14.908-40.96l-40.957,14.907l0,0l0,0l-96.248,35.035L262.77,395.884l-96.248,35.031c0.373,1.025-0.277,2.42,0.096,3.445
				C166.713,450.558,162.342,467.222,152.387,481.282z"/>
                        <path fill="#00A859" d="M0,190.033l55.863,26.049l0,0l80.237-28.045L192.108,67.93l141.3-51.43
				c-9.957,14.063-14.699,29.702-14.23,46.924c0.373,1.024-0.277,2.42,0.094,3.444L223.026,101.9l-56.008,120.107L70.77,257.041
				l-40.956,14.907l-14.907-40.959L0,190.033z"/>
                    </g>
                    <g>
                        <path opacity="0.1" fill="#FFFFFF" d="M53.698,306.159c21.437,9.268,45.07,14.412,69.911,14.412
				c97.393,0,176.346-78.952,176.344-176.347c0.002-8.691-0.639-17.23-1.854-25.586l-45.553,16.58l-55.357,118.715L53.698,306.159z"
                        />
                        <path opacity="0.1" fill="#FFFFFF" d="M266.486,40.859l-74.378,27.07l-56.008,120.108l-80.238,28.045L0,190.033l14.906,40.957
				l14.907,40.958l40.956-14.908l96.249-35.032L223.026,101.9l64.265-23.393C281.891,65.059,274.873,52.43,266.486,40.859z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
})

export const Logo3 = React.forwardRef(({ ...rest }, ref) => {

    return (
        <svg ref={ref} {...rest} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1600px"
            height="500px" viewBox="0 0 1600 500" enableBackground="new 0 0 1600 500">
            <g id="LOGORT">
                <g id="RIGTH">
                </g>
                <g id="LEFT">
                    <g>
                        <g>
                            <path d="M889.044,195.564l70.063,147.082c2.343,5.036,6.853,8.478,13.525,10.324c4.329,1.231,6.493,4.002,6.493,8.312
					c0,0.516-0.271,3.594-0.813,9.235c-0.54,2.462-4.33,3.692-11.363,3.692H859.266c-3.97,0-6.586-0.407-7.845-1.23
					c-1.265-0.82-1.893-3.078-1.893-6.771c0-0.616,0.176-3.283,0.54-8.004c0.542-1.847,2.706-3.18,6.492-4.002
					c5.769-1.128,8.655-3.027,8.655-5.697c0-0.719-0.181-1.592-0.54-2.618l-37.892-77.673l-9.179,11.592v62.843
					c0,5.238,4.06,9.245,12.175,12.015c3.425,1.131,5.14,3.694,5.14,7.697c0,4.722-0.723,7.875-2.164,9.465
					c-1.446,1.593-4.961,2.385-10.551,2.385H708.868c-4.152,0-7.035-0.489-8.657-1.471c-1.622-0.98-2.434-3.745-2.434-8.295
					c0-4.545,0.628-7.414,1.894-8.602c1.258-1.188,3.693-2.249,7.302-3.18c6.669-1.536,10.011-4.306,10.011-8.305V159.644
					c0-4.612-3.247-8.05-9.74-10.305c-4.328-1.54-6.944-2.794-7.844-3.771c-0.905-0.974-1.354-3-1.354-6.079
					c0-6.977,0.448-11.005,1.354-12.083c0.899-1.077,3.516-1.615,7.844-1.615h123.349c5.77,0,8.657,1.335,8.657,4.001
					c0,8.312-0.271,13.11-0.812,14.392c-0.542,1.284-3.158,2.694-7.846,4.232c-8.655,2.563-12.986,5.841-12.986,9.839v78.863
					l58.776-76.28c3.246-4.146,4.871-7.285,4.871-9.411c0-1.313-2.169-2.881-6.506-4.704c-4.717-1.847-7.577-3.436-8.575-4.771
					c-0.998-1.332-1.496-4.72-1.496-10.159c0-4.001,2.62-6.002,7.869-6.002h71.905c4.158,0,6.69,0.459,7.594,1.375
					c0.906,0.919,1.357,3.566,1.357,7.946c0,4.688-0.588,7.54-1.759,8.559c-1.175,1.021-4.826,2.242-10.954,3.668
					c-4.691,1.026-7.667,1.851-8.928,2.466c-1.263,0.615-2.797,1.95-4.598,4.003L889.044,195.564z"/>
                            <path d="M1192.304,125.79h95.759c6.49,0,10.727,0.538,12.712,1.615c1.982,1.078,2.976,3.824,2.976,8.234
					c0,4.002-0.677,6.799-2.027,8.389c-1.354,1.592-4.646,3.104-9.874,4.541c-4.692,1.231-7.668,2.566-8.926,4.002
					c-1.266,1.438-1.894,3.797-1.894,7.08v182.699c0,2.67,0.54,4.618,1.622,5.85s4.058,2.516,8.926,3.848
					c6.67,1.847,10.009,4.361,10.009,7.541c0,8.004-0.452,12.442-1.352,13.314c-0.905,0.872-4.058,1.307-9.468,1.307h-120.644
					c-5.232,0-7.844-2.513-7.844-7.54c0-4.31,0.271-7.517,0.811-9.62c0.542-2.102,3.877-3.821,10.009-5.156
					c6.852-1.54,10.279-4.257,10.279-8.158V214.138l-48.69,104.664h-29.754l-47.879-101.432v125.904
					c0,4.105,3.246,7.133,9.737,9.081c7.934,2.258,11.903,6.26,11.903,12.005c0,6.568-3.97,9.85-11.903,9.85h-58.697
					c-6.676,0-10.01-3.128-10.01-9.387c0-4.412,2.795-7.798,8.386-10.16c4.146-1.947,6.762-3.513,7.844-4.693
					c1.083-1.18,1.625-3.256,1.625-6.234V165.962c0-4.925-0.77-8.697-2.299-11.313c-1.536-2.617-4.829-4.745-9.874-6.389
					c-5.411-1.64-8.115-3.332-8.115-5.078l0.271-6.619c0.176-4.822,0.765-7.822,1.756-9.004c0.99-1.179,3.381-1.77,7.17-1.77
					h100.085l43.279,95.12L1192.304,125.79z"/>
                            <path d="M1442.839,289.558v51.254c0,5.952,4.507,9.852,13.525,11.698c4.329,0.923,7.346,2.181,9.063,3.771
					c1.711,1.592,2.57,4.54,2.57,8.85c0,4.105-0.994,6.646-2.978,7.618c-1.985,0.978-5.951,1.462-11.901,1.462h-122.537
					c-6.49,0-9.737-3.231-9.737-9.695c0-5.13,0.448-8.285,1.354-9.466c0.899-1.179,3.963-2.232,9.197-3.155
					c4.686-0.82,7.614-1.717,8.789-2.693c1.173-0.975,1.759-2.641,1.759-5.003V158.266c0-4.411-4.78-7.95-14.337-10.62
					c-5.049-1.539-7.665-3.897-7.844-7.08l-0.271-7.233c0-3.078,0.676-5.104,2.029-6.08c1.354-0.974,4.371-1.462,9.061-1.462
					h138.496c39.853,0,71.636,7.26,95.353,21.779c23.711,14.521,35.57,34.606,35.57,60.258c0,14.468-3.652,27.347-10.956,38.633
					c-7.304,11.289-16.499,19.601-27.59,24.936c-11.09,5.337-23.897,9.697-38.41,13.082c-14.52,3.387-29.985,5.08-46.392,5.08
					H1442.839z M1442.839,142.874v128.983h18.937c6.49,0,12.307-2.026,17.446-6.08c5.14-4.053,7.71-10.799,7.71-20.24v-76.806
					c0-17.238-10.103-25.857-30.296-25.857H1442.839z"/>
                        </g>
                        <g>
                            <path opacity="0.2" fill="#FFFFFF" d="M944.585,126.192h-16.51c2.658,7.652,5.698,15.122,9.101,22.393
					c1.075-0.265,2.279-0.544,3.647-0.844c6.127-1.426,9.779-2.647,10.954-3.668c1.17-1.019,1.759-3.871,1.759-8.559
					c0-4.38-0.452-7.027-1.357-7.946C951.275,126.652,948.743,126.192,944.585,126.192z"/>
                            <path opacity="0.2" fill="#FFFFFF" d="M1300.909,127.809c-1.985-1.077-6.222-1.616-12.713-1.616h-95.758l-44.091,95.121
					l-43.279-95.121h-100.085c-3.789,0-6.18,0.592-7.17,1.771c-0.992,1.181-1.58,4.182-1.757,9.004l-0.271,6.619
					c0,1.746,2.704,3.438,8.115,5.079c5.045,1.643,8.338,3.771,9.873,6.388c1.53,2.616,2.299,6.388,2.299,11.313v75.869
					c12.83,8.947,26.594,16.65,41.115,22.936v-47.398l26.979,57.156c21.095,6.195,43.407,9.534,66.505,9.534
					c0.104,0,0.21-0.003,0.315-0.003l32.524-69.919v67.634c35.745-4.986,68.92-17.986,97.652-37.136v-84.984
					c0-3.283,0.628-5.642,1.893-7.08c1.259-1.436,4.234-2.771,8.927-4.002c5.228-1.437,8.521-2.949,9.873-4.541
					c1.352-1.589,2.028-4.386,2.028-8.388C1303.885,131.633,1302.891,128.887,1300.909,127.809z"/>
                            <path opacity="0.2" fill="#FFFFFF" d="M1373.136,125.79h-42.555c-4.689,0-7.707,0.488-9.061,1.462
					c-1.354,0.976-2.029,3.002-2.029,6.08l0.271,7.233c0.179,3.183,2.795,5.541,7.844,7.08c9.557,2.67,14.337,6.209,14.337,10.62
					v27.577C1355.057,167.603,1365.624,147.412,1373.136,125.79z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fill="#FFCC29" d="M89.814,373.604c-34.869,0-63.2,28.327-63.2,63.196c0.001,34.869,28.332,63.201,63.2,63.199
					c34.869,0,63.199-28.332,63.199-63.199C153.013,401.932,124.683,373.604,89.814,373.604z M89.813,450.967
					c-7.627,0-14.166-6.539-14.165-14.166c0-8.717,6.539-15.254,14.166-15.254c7.627,0,14.165,6.537,14.164,15.254
					C103.98,444.43,97.442,450.967,89.813,450.967z"/>
                            <path fill="#FFCC29" d="M394.328,0c-34.869-0.001-63.199,28.33-63.199,63.199s28.332,63.199,63.199,63.197
					c34.869,0,63.199-28.33,63.199-63.197C457.527,28.33,429.197-0.001,394.328,0z M394.328,77.364
					c-7.629,0-14.166-6.539-14.166-14.165c0.002-8.718,6.539-15.255,14.166-15.255s14.166,6.537,14.164,15.255
					C408.494,70.827,401.955,77.364,394.328,77.364z"/>
                        </g>
                        <g>
                            <path fill="#00A859" d="M152.36,392.004l80.891-29.438l56.005-120.112l152.563-55.529l-19.752-54.268
					c-31.74,11.553-66.84,1.137-86.49-24.178c-0.373-1.024-1.77-1.676-2.143-2.7l-80.886,29.44l-55.358,118.714l-153.587,55.9
					l19.751,54.27c31.743-11.551,67.213-0.113,86.864,25.202C150.591,390.33,151.987,390.982,152.36,392.004z"/>
                            <path fill="#00A859" d="M152.387,481.281l141.301-51.428l56.004-120.11l80.24-28.043l0,0l55.863,26.049l-14.906-40.957
					l-14.908-40.96l-40.957,14.907l0,0l0,0l-96.248,35.035L262.77,395.883l-96.248,35.031c0.373,1.025-0.277,2.42,0.096,3.445
					C166.713,450.557,162.342,467.221,152.387,481.281z"/>
                            <path fill="#00A859" d="M0,190.033l55.863,26.049l0,0l80.237-28.045l56.008-120.106l141.3-51.43
					c-9.957,14.063-14.699,29.702-14.23,46.924c0.373,1.024-0.277,2.42,0.094,3.444l-96.245,35.032l-56.008,120.106L70.77,257.041
					l-40.956,14.907l-14.907-40.959L0,190.033z"/>
                        </g>
                        <g>
                            <path opacity="0.1" fill="#FFFFFF" d="M53.698,306.159c21.437,9.268,45.07,14.412,69.911,14.412
					c97.393,0,176.346-78.952,176.344-176.347c0.002-8.691-0.639-17.23-1.854-25.585l-45.552,16.579l-55.358,118.715L53.698,306.159
					z"/>
                            <path opacity="0.1" fill="#FFFFFF" d="M266.486,40.86l-74.378,27.07l-56.008,120.107l-80.238,28.045L0,190.033l14.906,40.957
					l14.907,40.958l40.956-14.908l96.249-35.032l56.009-120.106l64.265-23.393C281.891,65.06,274.873,52.431,266.486,40.86z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})