import React, { useEffect } from 'react'
import './Modalstyle.css'
export default React.forwardRef(({ children, show, trigShow, title }, ref)=>{

    useEffect(() => {
            function clickeado(e) {
                if (ref.current && !ref.current.contains(e.target)) {
                    
                    if(ref.current.classList.contains('Modal-show')){
                        trigShow(false)
                    }   
                }
            }
            if(show){
                window.addEventListener('mousedown',clickeado);
            }else{
                window.removeEventListener('mousedown',clickeado);
            }
        return () => {
            window.removeEventListener('mousedown',clickeado);
        }
    }, [show, trigShow, ref])

    

    return (
        <div ref={ref} id='Modal' className= {`Modal ${show ? 'Modal-show' : 'Modal-hide' } `}>
            <div className='modal-ctrl'>
                <p>{title ? title : ""}</p>
                <button className='close' type='button' onClick={() => trigShow(false)}>X</button>
            </div>
            {children}
        </div>
    )
})
