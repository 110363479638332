import React, { useEffect, useRef } from 'react'
import './style.css'
import './styleApp.css'
import {Logo, Logo3} from '../svg/Logo'
import Ham from '../svg/Hamburger'

export default function Navbar({children}) {
  const refi = useRef(null);
  const hamb = useRef(null);
  useEffect(() => {
    window.onscroll = (e) => {
      const element = document.getElementsByClassName('scroll-show');
      for (let el = 0; el < element.length; el++) {
        if (document.documentElement.scrollTop > 50) {
          element[el].classList.add('an-nav')
          element[el].classList.remove('an-navn')
  
        } else {
          if (element[el].classList.contains('an-nav')) {
            element[el].classList.add('an-navn')
            element[el].classList.remove('an-nav')
          }
        }
      }
    }
  }, [])

  function expande(e) {
    e.preventDefault();
    var menu = refi.current;
    if (menu.classList.contains('hide-nav') || menu.classList.contains('show-nav')){
      if (menu.classList.contains('hide-nav')){
        menu.classList.replace('hide-nav','show-nav')
        hamb.current.classList.replace('deg0','deg90')
        
      }else{
        menu.classList.replace('show-nav','hide-nav')
        hamb.current.classList.replace('deg90','deg0')
        
      }
    }else{
      menu.classList.add('show-nav')
      hamb.current.classList.add('deg90');
    }
  }

  function GotoTag(e, href){
    e.preventDefault();
    expande(e);
    window.location.href = href;
  }

  return (
    <div>
    <nav className='scroll-show'>
      <div className='navlogo scroll-show' style={{zIndex:'3'}}>
        <Logo3 className='logo tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' /><Ham ref={hamb} className='ham' c1='#009966' onClick={expande}/>
      </div>
      <div ref={refi} className='menu'>
        
        <button className='tr-opa-0p5-3y mouse-hand' type='link' onClick={(e)=> GotoTag(e, '#Content')}>Empresa</button>
        <button className='tr-opa-0p5-3y mouse-hand' type='link' onClick={(e)=> GotoTag(e, '#Services')} >Servicios</button>
        <button className='tr-opa-0p5-3y mouse-hand' type='link' onClick={(e)=> GotoTag(e, '#Portafolio')}>Portafolio</button>
        <button className='tr-opa-0p5-3y mouse-hand' type='link' onClick={(e)=> GotoTag(e, '#Contacto')}>Contacto</button>
        <button className='tr-opa-0p5-3y mouse-hand'>Tienda</button>
        <Logo className='icon tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' /><Logo className='icon tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
        
      </div>
    </nav>
    {children}
    </div>
  )
}
