import React from 'react'
import './style.css'


const Card = ({ children, ...rest }) => (

    <div className={rest.className ? `${rest.className} card` : `card`} >
        {children}
    </div>
);

Card.Image = ({ children, ...rest }) => (

    <div className={rest.className ? `${rest.className} card-image` : `card-image`} >
        {children}
    </div>

);

Card.Header = ({ children, ...rest }) => (

    <div className={rest.className ? `${rest.className} card-header` : `card-header`} >
        {children}
    </div>

);

Card.Body = ({ children, ...rest }) => (
    <div className={rest.className ? `${rest.className} card-body` : `card-body`} >
        {children}
    </div>

);

export default Card;

