import React, {useRef, useState} from 'react';
import './App.css';
import './Appphone.css'
import './animations.css'

import { Logo2, Logo } from './components/svg/Logo'
import Navbar from './components/navbars/Navbar'
import Card from './components/cards/Card'
import Modal from './components/modals/Modal'

function App() {
  const ModalRef = useRef(null)
  const [showm, setshowm] = useState(true)
  return (
    
      <Navbar >
      <div>
        <Logo2 className='fondo-img' c1='#009966' c2='#FFCC33' />
        <div className='fondo an-y-top'>
          
            
              <p>AUTOMATIZACIÓN Y CONTROL EN SU MÁXIMA EXPRESIÓN</p>
              <hr className='line-green-5px' />
            

           
              <p className='lower'>Empresa especializada en desarrollo de hardware, software, sistemas de control, automatización, diseño web, diseño electrónico y más para la industria.</p>
              <button href={'#Content'} className='boton-saber' type='button' onClick={() =>setshowm(true)}>Saber más...</button>
          
        </div>

        <div id='Content' className='Content'>
          <p className='upper'>!Tenemos todo lo que necesitas!</p>
          <hr className='line-white-5px' />
          <p>Desde el año 2015 nos hemos dedicado a generar nuevas tecnologías de calidad Mexicana de la industria en general, sabemos identificar con exactitud las necesidad que se presentan en la industria y somos capaces de ofrecer una solución integra y multidisciplinaria con nuestro amplio campo de aplicación.</p>
          <p>Hemos incursionado en diferentes sectores como lo ha sido la industria del Entretenimiento, Televisoras, Proyectos varios de automatización para industrias alimenticias, médica logística y también distribuyendo servicios administrativos vía WEB.</p>
        </div>

        <div id='Services' className='Services'>
          <p className='upper'>Servicios</p>
          <hr className='line-green-5px' />
          <div>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>PC's y Laptop's</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Mantenimiento y reparación de equipos de cómputo.</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>Diseño Mecánico</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Elaboración de planos, cálculos, fabricación, armado...</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>Suministro</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Circuitos integrados, equipos de medición, equipos para instrumentación... ¡visita nuestra TIENDA!</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>Importación</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Contamos con padrón de importadores para productos relacionados con la industria en general, si tienes algún producto que desees importar o estés buscando alguno, ¡pregúntanos!.</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>Diseño de Software</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Programación de PLC's, dispositivos embebidos, diseño web, SCADA etc.</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header>
                <span className='titulos'>Diseño de Hardware</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Desarrollo de circuitos electrónicos, equipos para instrumentación, diseños a la medida...</p>
              </Card.Body>
            </Card>
            <Card>
              <Card.Image>
                <Logo className='iconos tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
              </Card.Image>
              <Card.Header className='titulo'>
                <span className='titulos'>Soporte Técnico</span>
              </Card.Header>
              <Card.Body>
                <p className='desc'>Asesoría técnica y reparación de instrumentos electrónicos.</p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div id='Portafolio' className='Portafolio'>
          <Modal ref ={ModalRef} show={showm} trigShow={setshowm} title='Proyecto Spencer'>
            soy modal1
          </Modal>
          <p>hola</p>
        </div>
        <div id='Contacto' className='Contacto'>
          <p className='upper'>!Sigamos en Contacto!</p>
          <hr className='line-green-5px' />
          <p>¿Listo para realizar su proyecto con nosotros?</p>
          <p>Llámenos o escribanos un correo y nosotros nos contactaremos con usted a la brevedad..</p>
          <Card className='tarjetai'>
            <Card.Image>
              <Logo className='tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
            </Card.Image>
            <Card.Header className='titulo'>
              <span className='titulos'>ventas@solucioneskmp.com</span>
            </Card.Header>
          </Card>
          <Card className='tarjetad'>
            <Card.Image>
              <Logo className='tr-opa-0p5 mouse-hand' c1='#009966' c2='#FFCC33' />
            </Card.Image>
            <Card.Header className='titulo'>
              <span className='titulos'>+52-55-28-99-79-27</span>
            </Card.Header>
          </Card>
        </div>
      </div>
    </Navbar>

  );
}

export default App;
