import React from 'react'
import './style.css'

export default React.forwardRef(({ c1, ...rest }, ref) =>{
	var color = { c1: c1 ? c1 : 'black'}
	return (
        <svg ref = {ref} {...rest} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="500px" height="500px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
	<g>
		<path d="M500,251.613C500,278.336,482.601,300,461.14,300H38.861C17.399,300,0,278.336,0,251.613v-3.227
			C0,221.664,17.399,200,38.861,200H461.14c21.461,0,38.86,21.664,38.86,48.387V251.613z"/>
		<path d="M500,251.613C500,278.336,482.601,300,461.14,300H38.861C17.399,300,0,278.336,0,251.613v-3.227
			C0,221.664,17.399,200,38.861,200H461.14c21.461,0,38.86,21.664,38.86,48.387V251.613z"/>
		<path d="M500,251.613C500,278.336,482.601,300,461.14,300H38.861C17.399,300,0,278.336,0,251.613v-3.227
			C0,221.664,17.399,200,38.861,200H461.14c21.461,0,38.86,21.664,38.86,48.387V251.613z"/>
		<path d="M500,251.613C500,278.336,482.601,300,461.14,300H38.861C17.399,300,0,278.336,0,251.613v-3.227
			C0,221.664,17.399,200,38.861,200H461.14c21.461,0,38.86,21.664,38.86,48.387V251.613z"/>
		<path fill={color.c1} stroke="#211915" strokeWidth="1.4454" d="M500,251.613C500,278.336,482.601,300,461.14,300H38.861
			C17.399,300,0,278.336,0,251.613v-3.227C0,221.664,17.399,200,38.861,200H461.14c21.461,0,38.86,21.664,38.86,48.387V251.613z"/>
	</g>
	<g>
		<path d="M500,51.613C500,78.337,482.601,100,461.14,100H38.861C17.399,100,0,78.337,0,51.613v-3.226C0,21.664,17.399,0,38.861,0
			H461.14C482.601,0,500,21.664,500,48.387V51.613z"/>
		<path d="M500,51.613C500,78.337,482.601,100,461.14,100H38.861C17.399,100,0,78.337,0,51.613v-3.226C0,21.664,17.399,0,38.861,0
			H461.14C482.601,0,500,21.664,500,48.387V51.613z"/>
		<path d="M500,51.613C500,78.337,482.601,100,461.14,100H38.861C17.399,100,0,78.337,0,51.613v-3.226C0,21.664,17.399,0,38.861,0
			H461.14C482.601,0,500,21.664,500,48.387V51.613z"/>
		<path d="M500,51.613C500,78.337,482.601,100,461.14,100H38.861C17.399,100,0,78.337,0,51.613v-3.226C0,21.664,17.399,0,38.861,0
			H461.14C482.601,0,500,21.664,500,48.387V51.613z"/>
		<path fill={color.c1} stroke="#211915" strokeWidth="1.4454" d="M500,51.613C500,78.337,482.601,100,461.14,100H38.861
			C17.399,100,0,78.337,0,51.613v-3.226C0,21.664,17.399,0,38.861,0H461.14C482.601,0,500,21.664,500,48.387V51.613z"/>
	</g>
	<g>
		<path d="M500,451.613C500,478.337,482.601,500,461.14,500H38.861C17.399,500,0,478.337,0,451.613v-3.227
			C0,421.663,17.399,400,38.861,400H461.14c21.461,0,38.86,21.663,38.86,48.387V451.613z"/>
		<path d="M500,451.613C500,478.337,482.601,500,461.14,500H38.861C17.399,500,0,478.337,0,451.613v-3.227
			C0,421.663,17.399,400,38.861,400H461.14c21.461,0,38.86,21.663,38.86,48.387V451.613z"/>
		<path d="M500,451.613C500,478.337,482.601,500,461.14,500H38.861C17.399,500,0,478.337,0,451.613v-3.227
			C0,421.663,17.399,400,38.861,400H461.14c21.461,0,38.86,21.663,38.86,48.387V451.613z"/>
		<path d="M500,451.613C500,478.337,482.601,500,461.14,500H38.861C17.399,500,0,478.337,0,451.613v-3.227
			C0,421.663,17.399,400,38.861,400H461.14c21.461,0,38.86,21.663,38.86,48.387V451.613z"/>
		<path fill={color.c1} stroke="#211915" strokeWidth="1.4454" d="M500,451.613C500,478.337,482.601,500,461.14,500H38.861
			C17.399,500,0,478.337,0,451.613v-3.227C0,421.663,17.399,400,38.861,400H461.14c21.461,0,38.86,21.663,38.86,48.387V451.613z"/>
	</g>
</svg>
    )
}
)
